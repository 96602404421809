<template>
  <v-container class="px-0">
    <v-row class="mt-10 px-0" justify="center">
      <v-col cols="12">
        <v-sheet>
          <v-row class="pa-4" justify="center">
            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? 12 : 9"
              v-if="!loading"
            >
              <div v-if="false">
                <span class="wc-h5"> ¡Tu renovación fue exitosa! </span>
                <p class="body-2 primary-variant">
                  tu nueva fecha de renovación será el {{ expireDate }}
                </p>
              </div>
              <div v-else>
                <span class="wc-h5">¡Bienvenido!</span>
                <div class="body-2 primary-variant">
                  <p class="primary-variant body-2">
                    Estás a punto de comenzar, selecciona el tipo de membresía
                    que deseas renovar
                    <span
                      v-if="!(affiliate.role == 'MEMBER')"
                      class="primary-variant body-2"
                    >
                      para habilitar el tablero de tu nuevo afiliado vinculado </span
                    >.
                  </p>
                </div>
              </div>
            </v-col>

            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? 12 : 9"
              v-if="loading"
            >
              <v-skeleton-loader class="my-8" width="100%" type="paragraph" />
              <v-skeleton-loader width="50%" type="paragraph" />
            </v-col>

            <v-col v-else :cols="$vuetify.breakpoint.smAndDown ? 12 : 9">
              <!-- Member information -->
              <v-row class="mb-5">
                <v-col
                  :cols="$vuetify.breakpoint.smAndDown ? 6 : 'auto'"
                  v-for="item in Object.keys(affiliateInfo)"
                  :key="item"
                >
                  <P class="ma-0" v-if="affiliateInfo[item] != ''">
                    <span class="font-weight-bold">{{ constants[item] }}: </span
                    ><br />
                    <span class="body-2">{{ affiliateInfo[item] }}</span>
                  </P>
                </v-col>
              </v-row>

              <!-- National Panel -->
              <v-expansion-panels>
                <v-expansion-panel
                  v-if="
                    organizationPaymentType == 'NATIONAL' ||
                    organizationPaymentType == 'BOTH'
                  "
                >
                  <v-expansion-panel-header class="my-7">
                    <span>
                      <v-icon
                        size="30"
                        class="mr-3"
                        :color="
                          (affiliation.national && !affiliation.national.canRenew) ? '#6df4da' : '#f46743'
                        "
                      >
                        {{
                          (affiliation.national && !affiliation.national.canRenew)
                            ? "mdi-check-circle"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      <span class="text-primary-variant list-title">{{
                        isAmpi ? "Pago Nacional" : "Pagar Cuota"
                      }}</span>
                    </span>
                  </v-expansion-panel-header>
                  <v-template v-for="(level, i) in nationalList" :key="i">
                    <v-expansion-panel-content
                      class="mx-5"
                      v-if="isAssignedMembership(level._id)"
                    >
                      <v-row
                        @mouseover="mouseOverState = true"
                        @mouseleave="mouseOverState = false"
                      >
                        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
                          <p class="ma-0">
                            <span class="font-weight-bold">
                              {{  level ? (level.name ? level.name : 'N/D') : 'N/D'}} </span
                            ><br />
                            <span v-if="level.details != ''" class="body-2">
                              {{ level.details }}
                            </span>
                            <br v-if="level.details != ''" />
                            <span class="subtitle-2 mb-0" v-if="!isImefOrganization(user.headOffice._id)">
                              {{ level.availableAffiliationTypesForSale }}
                              accesos disponibles
                            </span>
                          </p>
                        </v-col>
                        <v-col
                          :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
                          class="d-flex align-center justify-center"
                        >
                          <p class="mb-0 currency-p body-2">
                            <span v-if="level.inscriptionConfig && level.inscriptionConfig.active">{{
                              level.inscriptionConfig
                                ? level.inscriptionConfig.price +
                                  "MXN Inscripción"
                                : "Inscripción Gratis"
                            }}</span
                            ><br />
                            <span
                              >{{ membershipPrice || level.formattedPrice }} MXN
                              Cuota</span
                            >
                          </p>
                        </v-col>

                        <v-col
                          :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
                          class="d-flex justify-end"
                        >
                          <div class="d-flex align-center">
                            <div
                              class="d-flex flex-column align-center"
                              v-if="level.inscriptionConfig && level.inscriptionConfig.active"
                            >
                              <v-btn
                                @click="
                                  level.paidInscription ||
                                    goToCheckout(level.affiliation, level._id)
                                "
                                rounded
                                depressed
                                :disabled="
                                  (affiliation.national.inscription != 'N/A' &&
                                    level.paidInscription == false) ||
                                  !['N/A', level.id].includes(
                                    affiliation.national.membership
                                  ) ||
                                  !isAssignedMembership(level._id)
                                "
                                :color="
                                  level.paidInscription
                                    ? color.active
                                    : color.secondaryVariant
                                "
                                fab
                                small
                                class="text-capitalize"
                              >
                                <v-icon>{{
                                  level.paidInscription
                                    ? "mdi-check-circle-outline"
                                    : "mdi-alert-circle-outline"
                                }}</v-icon>
                              </v-btn>
                              <span class="alert-span"
                                >Pagar <br />
                                Inscripción</span
                              >
                            </div>

                            <hr
                              v-if="level.inscriptionConfig && level.inscriptionConfig.active"
                              class="alert-divider"
                            />

                            <div class="d-flex flex-column align-center ml-3">
                              <v-btn
                                elevation="0"
                                fab
                                small
                                :disabled="
                                  (validationsForCheckout(level, 'national')
                                    .disabled && user.headOffice.id !='66fb2d896646ca001cb93b3f')||
                                  !isAssignedMembership(level._id)
                                "
                                class="text-capitalize"
                                @click="getFunctionCkeckout(level, 'national')"
                                :color="
                                  validationsForCheckout(level, 'national')
                                    .color
                                "
                              >
                                <v-icon>{{
                                  validationsForCheckout(level, "national").icon
                                }}</v-icon>
                              </v-btn>
                              <span class="alert-span">
                                Pagar <br />
                                Cuota
                              </span>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-template>
                </v-expansion-panel>

                <!-- Local Panel  -->
                <v-expansion-panel
                  v-if="
                    organizationPaymentType == 'LOCAL' ||
                    organizationPaymentType == 'BOTH'
                  "
                >
                  <v-expansion-panel-header class="my-7">
                    <p class="mb-0">
                      <v-icon
                        size="30"
                        class="mr-3"
                        :color="(affiliation.local && !affiliation.local.canRenew) ? '#6df4da' : '#f46743'
                        "
                      >
                        {{  (affiliation.local && !affiliation.local.canRenew)
                            ? "mdi-check-circle"
                            : "mdi-alert-circle-outline"
                        }}
                      </v-icon>
                      <span class="text-primary-variant list-title"
                        >Pago Local</span
                      >
                    </p>
                  </v-expansion-panel-header>
                  <!--  -->
                  <v-expansion-panel-content
                    v-for="(level, i) in localList"
                    :key="i"
                  >
                    <v-row
                      @mouseover="mouseOverState = true"
                      @mouseleave="mouseOverState = false"
                    >
                      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
                        <p class="ma-0">
                          <span class="font-weight-bold">
                            {{  level ? (level.name ? level.name : 'N/D') : 'N/D'}} </span
                          ><br />
                          <span v-if="level.details != ''" class="body-2">
                            {{ level.details }}
                          </span>
                          <br v-if="level.details != ''" />
                          <span class="subtitle-2 mb-0">
                            {{ level.availableAffiliationTypesForSale }} accesos
                            disponibles
                          </span>
                        </p>
                      </v-col>

                      <!-- Modify -->
                      <v-col
                        :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
                        class="d-flex align-center justify-center"
                      >
                        <p class="mb-0 currency-p body-2">
                          <span v-if="level.inscriptionConfig && level.inscriptionConfig.active">
                            {{
                              level.inscriptionConfig
                                ? level.inscriptionConfig.price +
                                  "MXN Inscripción"
                                : "Inscripción Gratis"
                            }}
                          </span>
                          <br />
                          <span>{{ level.formattedPrice }} MXN Cuota</span>
                        </p>
                      </v-col>

                      <v-col
                        :cols="$vuetify.breakpoint.smAndDown ? 12 : 4"
                        class="d-flex justify-end"
                      >
                        <div class="d-flex align-center">
                          <div
                            class="d-flex flex-column align-center"
                            v-if="level.inscriptionConfig && level.inscriptionConfig.active"
                          >
                            <v-btn
                              @click="
                                level.paidInscription ||
                                  goToCheckout(level.affiliation, level._id)
                              "
                              rounded
                              depressed
                              :color="
                                level.paidInscription
                                  ? color.active
                                  : color.secondaryVariant
                              "
                              fab
                              small
                              :disabled="
                                (affiliation.local.inscription != 'N/A' &&
                                  level.paidInscription == false) ||
                                !['N/A', level.id].includes(
                                  affiliation.local.membership
                                ) ||
                                !isAssignedMembership(level._id)
                              "
                              class="text-capitalize"
                            >
                              <!-- :disabled="level.availableAffiliationTypesForSale == 0 || (!isActivePaymentLocal && !level.paidInscription) || (!level.paidInscription && inscriptionLPaid)" -->
                              <v-icon>{{
                                level.paidInscription
                                  ? "mdi-check-circle-outline"
                                  : "mdi-alert-circle-outline"
                              }}</v-icon>
                            </v-btn>
                            <span class="alert-span"
                              >Pagar <br />
                              Inscripción</span
                            >
                          </div>

                          <hr
                            v-if="level.inscriptionConfig && level.inscriptionConfig.active"
                            class="alert-divider"
                          />

                          <div class="d-flex flex-column align-center ml-3">
                            <v-btn
                              elevation="0"
                              fab
                              small
                              :disabled="
                                (validationsForCheckout(level, 'local').disabled && user.headOffice.id !='66fb2d896646ca001cb93b3f') || !isAssignedMembership(level._id)
                              "
                              class="text-capitalize"
                              @click="getFunctionCkeckout(level, 'local')"
                              :color="
                                validationsForCheckout(level, 'local').color
                              "
                            >
                              <v-icon>{{
                                validationsForCheckout(level, "local").icon
                              }}</v-icon>
                            </v-btn>
                            <span class="alert-span">
                              Pagar <br />
                              Cuota
                            </span>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <!-- <v-col cols="2">
              <v-img
                src="https://drive.google.com/uc?export=view&id=1ayHBNWo0evV1zybw2TLy1kds6AORxjVc"
              >
              </v-img>
            </v-col> -->
            <v-col cols="9">
              <!-- <div class="mx-0 py-1" v-if="!loading">
                <div v-if="allPaymentAndAlloK">
                  <div class="subtitle-1 primary-variant font-weight-black">
                    ¡Gracias tu renovación fue exitosa!
                  </div>
                  <div class="body-2 primary-variant mt-2">
                    Tu nueva fecha de renovación será el {{ expireDate }}
                  </div>
                </div>
                <div v-else>
                  <div class="subtitle-1 primary-variant font-weight-black">
                    ¡Estás a punto de iniciar el proceso de renovación!
                  </div>
                  <div class="body-2 primary-variant mt-2">
                    Para continuar con el
                    {{
                      affiliate.role == "MEMBER"
                        ? "proceso,"
                        : "proceso y habilitar el tablero para tu nuevo afiliado vinculado"
                    }}
                    selecciona la cuota que deseas iniciar.
                  </div>
                </div>
                <br />
              </div> -->
            </v-col>
          </v-row>

          <!-- <v-row justify="center" class="mt-1 mb-4">
            <v-col
              cols="9"
              md="3"
              v-if="
                organizationPaymentType == 'NATIONAL' ||
                organizationPaymentType == 'BOTH'
              "
            >
              <v-card
                outlined
                :class="!isActivePaymentNational ? 'disabled-color' : ''"
                class="border py-8 px-2 text-center card-br-27"
                :to="urlNextPaymentN"
              >
                <v-row justify="center" class="ma-1">
                  <v-col cols="12 py-1">
                    <v-icon
                      class="icon-border"
                      :class="
                        !isActivePaymentNational
                          ? 'icon-success'
                          : 'icon-default'
                      "
                    >
                      {{
                        !isActivePaymentNational
                          ? "mdi-check"
                          : "mdi-dots-horizontal"
                      }}
                    </v-icon>
                  </v-col>
                  <v-col cols="12">
                    <h3 class="text-center">Pago Nacional</h3>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col
              cols="9"
              md="3"
              v-if="
                organizationPaymentType == 'LOCAL' ||
                organizationPaymentType == 'BOTH'
              "
            >
              <v-card
                outlined
                :class="!isActivePaymentLocal ? 'disabled-color' : ''"
                class="border py-8 px-2 text-center card-br-27"
                :to="urlNextPaymentL"
              >
                <v-row justify="center" class="ma-1">
                  <v-col cols="12 py-1">
                    <v-icon
                      class="icon-border"
                      :class="
                        !isActivePaymentLocal ? 'icon-success' : 'icon-default'
                      "
                    >
                      {{
                        !isActivePaymentLocal
                          ? "mdi-check"
                          : "mdi-dots-horizontal"
                      }}
                    </v-icon>
                  </v-col>
                  <v-col cols="12">
                    <h3 class="text-center">Pago Local</h3>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row> -->
          <!-- <v-row class="mx-4 py-8" justify="end">
            <v-btn small rounded color="primary" class="white--text text-capitalize" @click="backToAccount"
              v-if="allPaymentAndAlloK" :loading="loading">
              <span class="font-weight-bold"> Volver a mi cuenta </span>
            </v-btn>
          </v-row> -->
          <v-dialog v-model="showErrorDialog" max-width="600" persistent no-click-animation>
  <v-card>
    <v-card-title class="headline">Membresías</v-card-title>
    <v-card-text>Esta organización aún no ha creado una membresía.</v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="font-weight-bold black--text mx-2"
              color="accent"
              rounded
              elevation="0"
               @click="backToAccount">VOLVER AL INICIO</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import Endpoints from "@/share/Endpoints";
import {
  isActivePaymentNationalH,
  isActivePaymentLocalH,
} from "@/common/hook/useHelper.js";
import { formatPrice } from "@/utils";
import { colors } from "@/constants/colors.js";
import { isImefOrganization } from '../../../../share/imef';

export default {
  props: {
    membershipId: {
      type: String,
      default: "",
    },
    affiliationId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showErrorDialog: false,
      affiliationLevels: {
        selected: {
          id: null,
          name: "",
          price: null,
          formattedPrice: "",
        },
        levels: [],
      },
      color: colors,
      typePayment: "local",
      urlNextPaymentN: "",
      urlNextPaymentL: "",
      affiliateRole: "",
      mouseOverState: false,
      affiliate: {},
      currentBranch: "",
      affiliation: {},
      nationalAffiliationsList: {},
      localAffiliationsList: {},
      isActivePaymentNational: false,
      isActivePaymentLocal: false,
      loading: false,
      allPaymentAndAlloK: false,
      expireDate: "",
      isMember: false,
      membershipPrice: 0,
      constants: {
        name: "Usuario",
        createdAt: "F. Inicio",
        email: "Email",
        position: "Cargo",
        organization: "Organización",
      },
    };
  },
  async created() {
    this.loading = true;
    try {
        const result = await this.getAffiliate({
          membershipId: this.membershipId,
          affiliationId: this.affiliationId,
        });

        if (result.affiliate.membershipPrice > 0) {
          this.membershipPrice = result.affiliate.membershipPrice;
        }

        this.affiliate = result.affiliate;
        this.loading = false;
        this.affiliateRole = result.affiliate.validationStatus;
        this.affiliation = result.affiliate.affiliation;

        if (this.affiliation.national) {
          this.nationalAffiliationsList = this.formattedAffiliationLevels(
            this.affiliation.national.affiliationTypes
          );
        }

        if (this.affiliation.local) {
          this.localAffiliationsList = this.formattedAffiliationLevels(
            this.affiliation.local.affiliationTypes
          );
        }

        this.currentBranch = result.currentBranch;
        this.isMember = this.affiliate.role === "MEMBER";
        if (this.affiliation.national.affiliationTypes.length === 0) {
          this.showErrorDialog = true;
        }

    } catch (error) {
      this.showErrorDialog = true;
    } finally {
        this.loading = false;
    }
},

  methods: {
    isActivePaymentNationalH,
    isActivePaymentLocalH,
    ...mapActions("memberAffiliates", ["getAffiliate"]),
    ...mapActions("user", ["realoadUserData"]),
    isImefOrganization,
    formattedAffiliationLevels(affiliationLevels = []) {
      return affiliationLevels.map((level) => {
        if (level.hasOwnProperty("inscriptionConfig")) {
          level.inscriptionConfig.price = formatPrice(
            level.inscriptionConfig.price
          );
        }
        return {
          ...level,
          formattedPrice: formatPrice(level.price),
        };
      });
    },

    getFunctionCkeckout(level, type) {
      if (level.inscriptionConfig && level.inscriptionConfig.active && level.paidInscription == false) {
        return;
      } else {
        this.goToCheckout(level.affiliation, level._id);
      }
    },

    backToAccount() {
      this.$router.push({ name: "Member Home" });
      this.realoadUserData({ email: this.user.email });
    },

    validationsForCheckout(level, type) {
      const { active, secondaryVariant } = this.color;
      const { membership, inscription, canRenew } = this.affiliation[type];
      const { alert, check } = {
        alert: "mdi-alert-circle-outline",
        check: "mdi-check-circle-outline",
      };
      let variant = { color: secondaryVariant, icon: alert, disabled: false };

      // when level doesnt have inscription
      if (!level.inscriptionConfig || level.inscriptionConfig.active == false) {
        if (membership != "N/A" && membership == level.id) {
          // paid membership
          (variant.color = active), (variant.icon = check);
        } else if (membership != "N/A" && membership != level.id) {
          variant.disabled = true;
          variant.icon = alert;
        } else {
          // unpaid membership
          variant.icon = alert;
        }
      } else {
        // when level does have inscription
        if (inscription == "N/A" && this.user.validationStatus != "ACTIVE") {
          (variant.disabled = true), (variant.icon = alert);
        } else {
          if (membership != "N/A") {
            (variant.disabled = false),
              (variant.icon = check),
              (variant.color = active);
          } else {
            (variant.icon = alert),
              (variant.disabled = false),
              (variant.color = secondaryVariant);
          }
        }
      }
      if (
        inscription != level.id &&
        inscription != "N/A" &&
        this.user.validationStatus != "ACTIVE"
      ) {
        variant.disabled = true;
        variant.icon = alert;
      }
      if (canRenew) {
        variant.color = secondaryVariant;
        variant.icon = alert;
      } else {
        variant.disabled = true;
      }
      if (
        (!level.inscriptionConfig || level.inscriptionConfig.active == false) &&
        inscription == "N/A" &&
        membership == "N/A"
      ) {
        variant.disabled = false;
        variant.icon = alert;
      }
      return variant;
    },

    goToCheckout(affiliationId, affiliationType) {
      this.$router.push({
        name: "Member new Checkout",
        params: {
          affiliationId,
          affiliationType,
          membershipId: this.membershipId,
          organization: this.$route.params.affiliationId,
        },
      });
    },
    isAssignedMembership(id) {
      if (
        this.affiliate.assignedMembership &&
        this.affiliate.assignedMembership != ""
      ) {
        return this.affiliate.assignedMembership == id;
      }
      return true;
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    isAmpi() {
      //dev ampi: 62b622c66a7441ae20159d7d
      const ampi1 = "62b622c66a7441ae20159d7d";
      const ampi2 = "6297b0fec7bf8b6f826313be";
      return this.user.headOffice === ampi1 || this.user.headOffice === ampi2;
    },
    organizationPaymentType() {
      if (this.user.headOffice) {
        return this.user.headOffice.paymentType;
      } else {
        return "BOTH";
      }
    },
    userValid: {
      get() {
        return ["PROSPECTO", "EXPIRED"].includes(this.affiliateRole);
      },
    },
    nationalList() {
      return this.nationalAffiliationsList;
    },
    localList() {
      return this.localAffiliationsList;
    },
    inscriptionNPaid() {
      return this.nationalList.some((type) => type.paidInscription == true);
    },
    inscriptionLPaid() {
      return this.localList.some((type) => type.paidInscription == true);
    },
    affiliateInfo() {
      const { createdAt, email, name, firstLastname, role, position } =
        this.affiliate;
      return {
        name: name + " " + firstLastname,
        createdAt: moment(createdAt).format("YYYY-MM-DD"),
        position: role == "MEMBER" ? "Asociado" : position,
        email,
        organization: this.currentBranch,
      };
    },
  },
  watch: {
    affiliation(val) {
      if (val) {
        this.isActivePaymentNational = this.isActivePaymentNationalH(val);
        this.isActivePaymentLocal = this.isActivePaymentLocalH(val);
        this.typePayment = "";

        if (this.isMember) {
          this.urlNextPaymentN = this.isActivePaymentNational
            ? `/member/payment/${this.affiliation.national._id}`
            : "";
          this.urlNextPaymentL = this.isActivePaymentLocal
            ? `/member/payment/${this.affiliation.local._id}`
            : "";
        } else {
          this.urlNextPaymentN = this.isActivePaymentNational
            ? `/member/payment/${this.affiliation.national._id}/${this.affiliate._id}`
            : "";
          this.urlNextPaymentL = this.isActivePaymentLocal
            ? `/member/payment/${this.affiliation.local._id}/${this.affiliate._id}`
            : "";
        }

        this.allPaymentAndAlloK =
          !this.isActivePaymentNational &&
          !this.isActivePaymentLocal &&
          this.affiliate.validationStatus === "ACTIVE";
        if (this.allPaymentAndAlloK) {
          this.expireDate = moment(
            this.affiliation.national.details.inactiveDate
          ).format("MMMM DD YYYY");
        }
      }
    },
  },
};
</script>

<style scoped>
.disabled-color {
  background: #16283e !important;
  color: #fff !important;
}

.card-br-27 {
  border-radius: 27px !important;
}

.icon-default {
  border: thin solid rgb(0 0 0);
  color: rgb(0 0 0);
}

.icon-success {
  border: thin solid rgb(109 244 218);
  color: rgb(109 244 218);
}

.icon-border {
  border-radius: 50%;
}

.list-title {
  font-size: 16px;
  font-weight: 600;
}

.currency-p {
  color: rgb(153, 153, 153);
  font-size: 12px !important;
}

.alert-span {
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  line-height: 15px;
}

.alert-divider {
  border: 1px solid #777777;
  width: 35px;
  margin-inline: 10px;
  margin-bottom: 30px;
}
</style>